<template lang="pug">
  .login
    login-form(help='/login/password-forgot')
</template>

<script>
export default {
  name: 'LoginLogin',
  props: {
    titulo: String,
  },
  computed: {
    i18nScope: () => 'views.login.login',
  },

  // https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863
  created() {
    document.title = `PBConsig - ${this.titulo}`
  },
}
</script>
